
@import  "../../colors.scss";

.ui.segment.rolladie-container{

  grid-area: roll-side;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  margin: 0px;

  height: 100%;

  background-color: $blue-base-trans;

}

.fake-background{
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 17px 9px #888888;
  padding: 0;
  margin: 0;
}

.container{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 6000px;
  perspective-origin: 50%;
}

.dice{
  height: 250px;
  width: 250px;
  position: relative;
  transform-style: preserve-3d;
}

.spinning-true{
  animation: rotate 3s infinite;
}

@keyframes rotate{
  100%{
    transform: rotateY(360deg)
  }
}

.face{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;

  background-color: $orange-base;


  height: 250px;
  width: 250px;

  border: 7px solid$orange-dark;
  box-sizing: border-box;


  margin: auto;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.face-text{
  font-size: 40px;
  font-weight: 900;
}

.front{
  transform: translateZ(125px);
}
.back{
  transform: translateZ(-125px) rotateY(180deg);
}
.left{
  transform: rotateY(270deg) translateX(-125px);
  transform-origin: center left;
}
.right{
  transform: rotateY(-270deg) translateX(125px);
  transform-origin: top right;
}

.ui.button.roll-button{
  margin-top: 40px;
  border: 1px solid$orange-dark;
  background-color: $orange-light;
}


@media (max-width: 1250px){

  .fake-background{
    width: 170px;
    height: 170px;
    box-shadow: 0px 0px 20px 7px #888888;
  }


  .dice{
    height: 170px;
    width: 170px;
  }

  .face{
    height: 170px;
    width: 170px;

    border: 2px solid$orange-dark;
    box-sizing: border-box;

  }

  .face-text{
    font-size: 30px;
    font-weight: 900;
  }

  .front{
    transform: translateZ(85px);
  }
  .back{
    transform: translateZ(-85px) rotateY(180deg);
  }
  .left{
    transform: rotateY(270deg) translateX(-85px);
  }
  .right{
    transform: rotateY(-270deg) translateX(85px);
  }
}

@media (max-width: 450px){

  .ui.segment.rolladie-container{
    max-width: 200px;
  }

  .fake-background{
    width: 110px;
    height: 110px;
    box-shadow: 0px 0px 17px 9px #888888;
  }


  .dice{
    height: 110px;
    width: 110px;
  }

  .face{
    height: 110px;
    width: 110px;

    border: 2px solid$orange-dark;
    box-sizing: border-box;

  }

  .face-text{
    font-size: 20px;
    font-weight: 900;
  }

  .front{
    transform: translateZ(55px);
  }
  .back{
    transform: translateZ(-55px) rotateY(180deg);
  }
  .left{
    transform: rotateY(270deg) translateX(-55px);
  }
  .right{
    transform: rotateY(-270deg) translateX(55px);
  }
}