@import  "../../colors.scss";

.ui.segment.chat-container{
  grid-area: chat-side;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  margin: 0px;

  height: 100%;

  background-color: $blue-base-trans
}

.ui.segment.chat-box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: 400px;
  max-width: 570px;

  background: $orange-base;
  border: 1px solid$orange-dark;
}

.chat-messages{
  overflow: auto;
}

.e-container{
  display: flex;
  flex-wrap: wrap;

  .button{
    margin: 5px;
  }

}

@media (max-width: 1250px){

  .ui.segment.chat-containe{
    max-height: 450px
  }

  .ui.segment.chat-box{
    max-height: 280px
  }
  .chat-messages{
    height:100%
  }
}


@media (max-width: 450px){

  .ui.segment.chat-container{
    max-width: 200px;
  }

  .e-container{

      .button-emoji{
        width: 15px
      }

  }
}