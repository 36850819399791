
@import  "../../colors.scss";

.main-body-unauth{
 background-color: $brown-dark-trans;
}

.error-message {
  color: red
}

.ui.segment.name-form-container{
  background-color: $blue-light;
}

.name-form-prompt{
  margin: 15px 15px 5px;
}

.error-message{
  margin: 0px 15px;
}

.name-input-form{
  margin: 0px 15px 15px;
}

.ui.button.name-input-submit{
  margin-left: 10px;
  background: $orange-base;
  border: 1px solid$gray-border;
}

.clear-container{
  left: 0;
  right: 0;
}