#root{
  height: 100%
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  height: 100%;
}

.main-body-auth{
  min-height: 900px;
}

@media (min-width: 1250px){
  .main-body-auth{
    min-height: 500px;
  }
}

@media (max-width: 450px){
  .main-body-auth{
    min-height: 1000px;
  }
}