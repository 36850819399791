
// $blue-darkest: #192231;
// $blue-darker: #3f567b;
// $blue-dark: #5878ac;
// $blue-base: #7dabf5;
// $blue-light: #97bcf7;
// $blue-lighter: #bed5fa;
// $blue-lightest: #e5eefd;


// $orange-darkest: #312419;
// $orange-darker: #7b5b3f;
// $orange-dark: #ac7f58;
// $orange-base: #f5b57d;
// $orange-light: #f7c497;
// $orange-lighter: #fadabe;
// $orange-lightest: #fdf0e5;

$blue-base: #e8edfe;
$blue-base: #e8edfe;
$blue-base-trans: #e8edfe78;
$blue-light: #edf1fe;

$orange-dark:#c4a566;
$orange-base: #ffecc4;
$orange-light: #fff3d9;
$orange-lighter: #fff8f0;

$brown-dark: #d7c29c;
$brown-dark-trans: #d7c29c24;


$gray-border: #d9d7da;

