
@import  "../../colors.scss";

.main-body-auth{
  background-color: #ffa50014;
 }

.auth-container{
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  max-height: 850px;
}

.roll-chat-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 4fr 6fr;
  grid-template-areas:
  "roll-side chat-side"
  "roll-side chat-side";
  grid-gap: 30px;

  height:100%
}

.clear-container{
  height: 100%;
  width: 100%;
  position: fixed;
  top:0;
  bottom:0;
  z-index: -1
}

.ui.button.signOut{
  background: $blue-base;
  border: 1px solid$gray-border

}

@media (max-width: 1250px){

  .roll-chat-container{
    grid-template-areas:
    "roll-side roll-side"
    "chat-side chat-side";
  }
}

@media (min-width: 1250px){

  .auth-container{
    max-height: 800px
  }
}


@media (max-width: 450px){
  .auth-container{
    max-height: 1000px;
  }
}