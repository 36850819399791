

.tester{
  height: 600;
  width: 600
}

[class|="emoji"] {
  position: absolute;
}

.e-container{
  display: flex;
  justify-content: space-evenly;
}

.red {
  background-color: #E94A3F;
}

.yellow {
  background-color: #FAA040;
}

.blue {
  background-color: #5FC9F5;
}

.button-emoji{
  width: 30px
}